import { gql, useQuery } from "@apollo/client";
export const QUERY_GET_AUDIT_TRAIL_EVENTS = gql`
  query getAuditTrailEvents($page: Int, $pageSize: Int) {
    getAuditTrailEvents(input: { page: $page, pageSize: $pageSize }) {
      auditTrail {
        __typename
        id
        created
        userId
        repository {
          id
          name
        }
        data
        actionType
      }
      totalRowCount
    }
  }
`;

export function useGetAuditTrailEventsQuery(page: number, pageSize: number) {
  const {
    data: auditTrailEvent,
    loading: isLoading,
    error
  } = useQuery(QUERY_GET_AUDIT_TRAIL_EVENTS, {
    variables: { page: page, pageSize: pageSize },
    fetchPolicy: "no-cache"
  });

  return {
    auditTrailEvent,
    isLoading,
    error
  };
}
