import { gql, useQuery } from "@apollo/client";
export const GET_EXECUTION_IMAGES = gql`
  query {
    getImages {
      images {
        id
        name
        environmentType
        environmentTypeVersion
        validationType
        validationTypeVersion
        digest
        updatedOn
        requirements {
          isMultinode
        }
        packages {
          name
          version
        }
        validatedPackages {
          name
          version
        }
        addons {
          name
        }
        recommendations {
          command
          isMultinode
        }
      }
    }
  }
`;

export function useListExecutionImagesQuery() {
  const {
    data: executionImages,
    loading: isLoadingGetExecutionImages,
    error: errorGetExecutionImages
  } = useQuery(GET_EXECUTION_IMAGES, {
    context: { clientName: "execution" }
  });
  return {
    executionImages,
    isLoadingGetExecutionImages,
    errorGetExecutionImages
  };
}
