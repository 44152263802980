import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_MODSPACE_CONTENT = gql`
  query getModspaceMeta(
    $username: String!
    $encryptedPassword: String!
    $search: String!
  ) {
    getModspaceMeta(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        search: $search
      }
    ) {
      modspaceFolder
      modspaceRevisions
    }
  }
`;

export function useGetModspaceMetadataQuery() {
  const [getModspaceMeta, { error, loading, data }] = useLazyQuery(
    QUERY_GET_MODSPACE_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getModspaceMeta,
    errorModspaceMetadata: error,
    loadingModspaceMetadata: loading,
    modspaceMetadata: data
  };
}
