import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_SVN_CONTENT = gql`
  query getSpmReMeta(
    $username: String!
    $encryptedPassword: String!
    $search: String!
  ) {
    getSpmReMeta(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        search: $search
      }
    ) {
      spmReFolder
      spmReRevisions
    }
  }
`;

export function useGetSvnMetadataQuery() {
  const [getSpmReMeta, { error, loading, data }] = useLazyQuery(
    QUERY_GET_SVN_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getSpmReMeta,
    errorMetadata: error,
    loadingMetadata: loading,
    metadata: data
  };
}
