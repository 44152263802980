import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_MODSPACE_CONTENT = gql`
  query getModspaceContent(
    $username: String!
    $encryptedPassword: String!
    $modspaceFolder: String!
    $modspaceRevision: String
  ) {
    getModspaceContent(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        modspaceFolder: $modspaceFolder
        modspaceRevision: $modspaceRevision
      }
    ) {
      name
      size
    }
  }
`;

export function useGetModspaceContentQuery() {
  const [getModspaceContent, { error, loading, data }] = useLazyQuery(
    QUERY_GET_MODSPACE_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getModspaceContent,
    error,
    loading,
    data
  };
}
