// TODO: have a talk with frontend team which tool to use to generate queries
//       I just used https://www.graphql-code-generator.com/ to generate
//       the types below from the schema definition of the backend
//       but there are also more sophisticated solutions
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type Query = {
  __typename?: "Query";
  getUsers: Array<Maybe<User>>;
  getRepoObjects?: Maybe<Array<Maybe<GetRepoObjectQueryData>>>;
  getPathVersions: Array<RepoObject>;
  getHistoryVersions: Array<RepoObject>;
  getRepos: Array<Maybe<Repo>>;
  getRepoDetails: Repo;
  getAuditTrailEvents?: Maybe<Array<Maybe<AuditTrailEvent>>>;
  getFilePartialContent: FilePartialContent;
  getDownloadLinks: GetDownloadLinksOutput;
  getMyPmxActivities: Array<Maybe<PmxActivity>>;
  getPmxActivityDetails: PmxActivity;
  getTraceabilityReport: Array<Maybe<Action>>;
  getMyActions: GetMyActionsOutput;
  getPmxActions: Array<Maybe<Action>>;
  getCreationalActions: Array<Maybe<Action>>;
  getConsumingActions: Array<Maybe<Action>>;
  getSwanContent: Array<Maybe<SwanFile>>;
  getAccessRights?: Maybe<AccessRight>;
  getModspaceContent: Array<Maybe<ModspaceFile>>;
  getModspaceMeta: Array<Maybe<ModspaceMeta>>;
  getCommentDetails: Comment;
  getLoginTokenForSpectrum?: Maybe<SpectrumLoginData>;
  getSpectrumStudyList: Array<Maybe<SpectrumStudy>>;
  getDatasetForStudy: Array<Maybe<SpectrumDataset>>;
  getSpmReContent: Array<Maybe<SpmReContent>>;
  getSpmReMeta: Array<Maybe<SpmReMeta>>;
  getTableQueryData: SpectrumTableQueryData;
  getActivityTemplates: ActivityTemplate;
};

export type QueryGetUsersArgs = {
  input?: InputMaybe<GetUsersInput>;
};

export type QueryGetRepoObjectsArgs = {
  input: GetRepoObjectsInput;
};

export type QueryGetPathVersionsArgs = {
  input?: InputMaybe<GetPathVersionsInput>;
};

export type QueryGetHistoryVersionsArgs = {
  input?: InputMaybe<GetHistoryVersionsInput>;
};

export type QueryGetRepoDetailsArgs = {
  input: GetRepoDetailsInput;
};

export type QueryGetAuditTrailEventsArgs = {
  input?: InputMaybe<GetAuditTrailEventsInput>;
};

export type QueryGetFilePartialContentArgs = {
  input?: InputMaybe<GetFilePartialContentInput>;
};

export type QueryGetDownloadLinksArgs = {
  input?: InputMaybe<GetDownloadLinksInput>;
};

export type QueryGetPmxActivityDetailsArgs = {
  input: GetPmxActivityDetailsInput;
};

export type QueryGetTraceabilityReportArgs = {
  input: GetTraceabilityReportInput;
};

export type QueryGetMyActionsArgs = {
  input?: InputMaybe<GetMyActionsInput>;
};

export type QueryGetPmxActionsArgs = {
  input?: InputMaybe<GetPmxActionsInput>;
};

export type QueryGetCreationalActionsArgs = {
  input?: InputMaybe<GetCreationalActionsInput>;
};

export type QueryGetConsumingActionsArgs = {
  input?: InputMaybe<GetConsumingActionsInput>;
};

export type QueryGetSwanContentArgs = {
  input?: InputMaybe<GetSwanContentInput>;
};

export type QueryGetAccessRightsArgs = {
  input?: InputMaybe<GetAccessRightsInput>;
};

export type QueryGetModspaceContentArgs = {
  input?: InputMaybe<GetModspaceContentInput>;
};

export type QueryGetCommentDetailsArgs = {
  input: GetCommentDetailsInput;
};

export type QueryGetLoginTokenForSpectrumArgs = {
  input: GetLoginTokenForSpectrumInput;
};

export type QueryGetSpectrumStudyListArgs = {
  input?: InputMaybe<GetSpectrumStudyListInput>;
};

export type QueryGetDatasetForStudyArgs = {
  input?: InputMaybe<GetDatasetForStudyInput>;
};

export type QueryGetSpmSvnContentArgs = {
  input?: InputMaybe<GetModspaceContentInput>;
};

export type QueryGetTableQueryDataArgs = {
  input?: InputMaybe<GetTableQueryDataInput>;
};

export enum AuditTrailEventActionType {
  AddExternalUser = "AddExternalUser",
  CreateRepository = "CreateRepository",
  CreateUser = "CreateUser",
  DeleteRepository = "DeleteRepository",
  EditRepository = "EditRepository",
  CreateFile = "CreateFile",
  ActivateUser = "ActivateUser",
  CreatePmxActivity = "CreatePmxActivity",
  EditPmxActivity = "EditPmxActivity",
  DeleteAccessRights = "DeleteAccessRights",
  EditAccessRights = "EditAccessRights",
  CreateAction = "CreateAction",
  RenameObject = "RenameObject",
  CopyObject = "CopyObject",
  MoveObject = "MoveObject",
  HideObject = "HideObject",
  EditAction = "EditAction",
  Restore = "Restore"
}

export enum ActionType {
  Download = "DOWNLOAD",
  SwanImport = "SWAN_IMPORT",
  Upload = "UPLOAD",
  AdfCreation = "ADF_CREATION",
  Archive = "ARCHIVE",
  Uncompress = "UNCOMPRESS",
  ModspaceExport = "MODSPACE_EXPORT",
  ModspaceImport = "MODSPACE_IMPORT",
  Rename = "RENAME",
  Copy = "COPY",
  Move = "MOVE",
  Hide = "HIDE",
  PlotCreation = "PLOT_CREATION",
  ReportCreation = "REPORT_CREATION",
  ModelExecution = "MODEL_EXECUTION",
  OtherAction = "OTHER_ACTION",
  SpectrumImport = "SPECTRUM_IMPORT",
  SpmSvnExport = "SPM_SVN_EXPORT",
  SpmSvnImport = "SPM_SVN_IMPORT"
}

export enum ActionStatus {
  Success = "Success",
  Pending = "Pending",
  Error = "Error"
}

export enum QualityCheckStatus {
  Qced = "QCED",
  QcReady = "QC_READY",
  QcFailed = "QC_FAILED"
}

export enum ActionSource {
  ExternalSystem = "EXTERNAL_SYSTEM",
  PhilBaseSystem = "PHIL_BASE_SYSTEM",
  PhilInteractiveEnvironment = "PHIL_INTERACTIVE_ENVIRONMENT"
}

export enum ActionFileType {
  Input = "Input",
  Output = "Output",
  Describing = "Describing",
  Script = "Script"
}

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  userGroup?: Maybe<Scalars["String"]>;
  roleArn?: Maybe<Scalars["String"]>;
  isPmx?: Maybe<Scalars["Boolean"]>;
  company?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type RepoObject = {
  __typename?: "RepoObject";
  name: Scalars["String"];
  isValid: Scalars["Boolean"];
  isDir: Scalars["Boolean"];
  revision?: Maybe<Scalars["Int"]>;
  versionId?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["String"]>;
  joinCommentType?: Maybe<Scalars["Int"]>;
  md5?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Float"]>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  repo?: Maybe<Repo>;
};

export type GetRepoObjectQueryData = {
  __typename?: "GetRepoObjectQueryData";
  repoObjects: Array<RepoObject>;
  isReadOnlyRepository: Scalars["Boolean"];
  repo: Repo;
};

export type Repo = {
  __typename?: "Repo";
  id: Scalars["ID"];
  name: Scalars["String"];
  isStandard: Scalars["Boolean"];
  accessRight?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["String"]>;
};

export type AuditTrailEvent = {
  __typename?: "AuditTrailEvent";
  id: Scalars["ID"];
  created: Scalars["String"];
  userId: Scalars["String"];
  repository?: Maybe<Repo>;
  actionType: AuditTrailEventActionType;
  data?: Maybe<Scalars["JSON"]>;
};

export type FilePartialContent = {
  __typename?: "FilePartialContent";
  content: Scalars["String"];
  isTruncated: Scalars["Boolean"];
  isPreviewAvailable: Scalars["Boolean"];
};

export type GetDownloadLinksOutput = {
  __typename?: "GetDownloadLinksOutput";
  actionId: Scalars["ID"];
  actionStatus: ActionStatus;
  presignedUrls?: Maybe<Array<DownloadLink>>;
};

export type GetMyActionsOutput = {
  __typename?: "GetMyActionsOutput";
  actions: Array<Maybe<Action>>;
  totalRowCount?: Maybe<Scalars["Int"]>;
};

export type DownloadLink = {
  __typename?: "DownloadLink";
  url: Scalars["String"];
  absolutePath: Scalars["String"];
};

export type HistoryLabel = {
  __typename?: "HistoryLabel";
  id: Scalars["ID"];
  name: Scalars["String"];
  actionId: Scalars["ID"];
  createdBy: Scalars["String"];
};

export type Action = {
  __typename?: "Action";
  id: Scalars["ID"];
  actionType: ActionType;
  actionFile: Array<Maybe<ActionFile>>;
  startDatetime?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  description?: Maybe<Scalars["String"]>;
  pmxActivity?: Maybe<PmxActivity>;
  sourceFolder?: Maybe<Scalars["String"]>;
  sourceFiles?: Array<Maybe<Scalars["String"]>>;
  actionStatus: ActionStatus;
  actionSource: ActionSource;
  actionQualityCheckStatus?: Maybe<QualityCheckStatus>;
  actionStatusDescription?: Maybe<Scalars["String"]>;
  actionHistory?: Maybe<Array<Maybe<ActionHistory>>>;
  additionalDetails?: Maybe<Scalars["String"]>;
  jobId?: Maybe<Scalars["ID"]>;
  historyLabel?: Maybe<HistoryLabel>;
};

export type ActionFile = {
  __typename?: "ActionFile";
  actionFileType: ActionFileType;
  file?: Maybe<RepoObject>;
};

export type ActionHistory = {
  __typename?: "ActionHistory";
  id: Scalars["ID"];
  updated: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  actionQualityCheckStatus?: Maybe<QualityCheckStatus>;
  historyLabel?: Maybe<HistoryLabel>;
  userId: Scalars["String"];
};

export type PmxActivity = {
  __typename?: "PmxActivity";
  id: Scalars["ID"];
  trialNumber: Scalars["String"];
  mainRepository: Repo;
  bayNumber?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  trialShortDescription?: Maybe<Scalars["String"]>;
  conversations: Array<Maybe<Conversation>>;
  allAccessUser: Array<Maybe<User>>;
  readOnlyRepositories?: Maybe<Array<Maybe<Repo>>>;
};

export type Conversation = {
  __typename?: "Conversation";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  comments: Array<Maybe<Comment>>;
};

export type Comment = {
  __typename?: "Comment";
  id: Scalars["ID"];
  text: Scalars["String"];
  created?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  referencedFiles?: Maybe<Array<RepoObject>>;
  referencedActions?: Maybe<Array<Action>>;
  replyComment?: Maybe<Comment>;
  pmxActivity?: Maybe<PmxActivity>;
};

export type SwanFile = {
  __typename?: "SwanFile";
  name: Scalars["String"];
  size?: Maybe<Scalars["Float"]>;
};

export type ModspaceFile = {
  __typename?: "ModspaceFile";
  name: Scalars["String"];
  size?: Maybe<Scalars["Float"]>;
};

export type SpectrumLoginData = {
  __typename?: "SpectrumLoginData";
  tokenType?: Maybe<Scalars["String"]>;
  expiresIn?: Maybe<Scalars["Int"]>;
  accessToken: Scalars["String"];
};

export type SpmReContent = {
  __typename?: "SpmReContent";
  name: Scalars["String"];
  size?: Maybe<Scalars["Float"]>;
};

export type SpmReMeta = {
  __typename?: "SpmReMeta";
  spmReFolder: Scalars["String"];
  spmReRevisions: Array<Scalars["String"]>;
};

export type ModspaceMeta = {
  __typename?: "ModspaceMeta";
  modspaceFolder: Scalars["String"];
  modspaceRevisions: Array<Scalars["String"]>;
};

export type AccessRight = {
  __typename?: "AccessRight";
  name?: Maybe<Scalars["String"]>;
  reader?: Maybe<Array<Maybe<Scalars["String"]>>>;
  contributor?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type GetRepoObjectsInput = {
  activityId: Scalars["ID"];
  forceRefresh?: InputMaybe<Scalars["Boolean"]>;
};

export type GetPathVersionsInput = {
  repoId?: InputMaybe<Scalars["ID"]>;
  activityId?: InputMaybe<Scalars["ID"]>;
  absolutePath?: InputMaybe<Scalars["String"]>;
  forceRefresh?: InputMaybe<Scalars["Boolean"]>;
};

export type GetHistoryVersionsInput = {
  repoId?: InputMaybe<Scalars["ID"]>;
  versionId: Scalars["ID"];
  forceRefresh?: InputMaybe<Scalars["Boolean"]>;
};

export type GetRepoDetailsInput = {
  repoId: Scalars["ID"];
};

export type GetCommentDetailsInput = {
  commentId: Scalars["ID"];
};

export type GetLoginTokenForSpectrumInput = {
  spectrumUser: Scalars["String"];
  encryptedPassword: Scalars["String"];
};

export type GetAuditTrailEventsInput = {
  startDate?: InputMaybe<Scalars["Int"]>;
  endDate?: InputMaybe<Scalars["Int"]>;
  actionType?: InputMaybe<AuditTrailEventActionType>;
};

export type GetFilePartialContentInput = {
  absolutePath: Scalars["String"];
  repoId: Scalars["ID"];
  versionId?: InputMaybe<Scalars["ID"]>;
  lineLimit?: InputMaybe<Scalars["Int"]>;
};

export type GetVersionPartialContentInput = {
  versionId: Scalars["ID"];
  repoId: Scalars["ID"];
  lineLimit?: InputMaybe<Scalars["Int"]>;
};

export type GetDownloadLinksInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<InputMaybe<Scalars["String"]>>;
  versionIds: Array<InputMaybe<Scalars["ID"]>>;
  repoId?: InputMaybe<Scalars["ID"]>;
};

export type GetPmxActivityDetailsInput = {
  id: Scalars["ID"];
};

export type GetMyActionsInput = {
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type GetTraceabilityReportInput = {
  versionId: Scalars["ID"];
};

export type GetPmxActionsInput = {
  activityId: Scalars["ID"];
};

export type GetCreationalActionsInput = {
  versionIds: Array<Scalars["ID"]>;
};

export type GetConsumingActionsInput = {
  versionIds: Array<Scalars["ID"]>;
};

export type GetUsersInput = {
  forceRefresh?: InputMaybe<Scalars["Boolean"]>;
};

export type GetSwanContentInput = {
  swanUser: Scalars["String"];
  encryptedPassword: Scalars["String"];
  absolutePath: Scalars["String"];
};

export type GetModspaceContentInput = {
  modspaceUser: Scalars["String"];
  encryptedPassword: Scalars["String"];
  absolutePath: Scalars["String"];
};

export type GetSpmSvnContentInput = {
  spmSvnUser: Scalars["String"];
  encryptedPassword: Scalars["String"];
  absolutePath: Scalars["String"];
};

export type GetAccessRightsInput = {
  repoId?: InputMaybe<Scalars["ID"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  deleteTestRepos: Scalars["Boolean"];
  uploadFile: RepoObject;
  createUploadLinks: CreateUploadLinksOutput;
  createMultipartUpload: CreateMultipartUploadOutput;
  getMultipartUploadLinks: GetMultipartUploadLinksOutput;
  completeMultipartUpload: CompleteMultipartUploadOutput;
  renameObjects: Array<Maybe<Scalars["String"]>>;
  copyObjects: Array<Maybe<Scalars["String"]>>;
  moveObjects: Array<Maybe<Scalars["String"]>>;
  hideObjects: Array<Maybe<Scalars["String"]>>;
  addExternalUser: User;
  createPmxActivity: PmxActivity;
  editPmxActivity: Scalars["Boolean"];
  deletePmxActivity: Scalars["Boolean"];
  createFolder: RepoObject;
  createArchive: CreateArchiveOutput;
  activateUser: Scalars["Boolean"];
  createConversation: Conversation;
  createComment: Comment;
  editComment: Comment;
  deleteComment: Scalars["Boolean"];
  importSwanContent: ImportSwanContentOutput;
  createAction: Action;
  updateActionStatus: Action;
  editAccessRights?: Maybe<AccessRight>;
  deleteAccessRights?: Maybe<AccessRight>;
  exportToModspace: ExportToModspaceOutput;
  exportToSpmSvn: ExportToSpmSvnOutput;
  importModspaceContent: ImportModspaceContentOutput;
  importSpectrumData: Scalars["Boolean"];
  editAction: Scalars["Boolean"];
};

export type MutationUploadFileArgs = {
  input: UploadFileInput;
};

export type MutationCreateUploadLinksArgs = {
  input: CreateUploadLinksInput;
};

export type MutationCreateMultipartUploadArgs = {
  input: CreateMultipartUploadInput;
};

export type MutationGetMultipartUploadLinksArgs = {
  input: GetMultipartUploadLinksInput;
};

export type MutationCompleteMultipartUploadArgs = {
  input: CompleteMultipartUploadInput;
};

export type MutationRenameObjectsArgs = {
  input: RenameObjectsInput;
};

export type MutationCopyObjectsArgs = {
  input: CopyObjectsInput;
};

export type MutationMoveObjectsArgs = {
  input: MoveObjectsInput;
};

export type MutationHideObjectsArgs = {
  input: HideObjectsInput;
};

export type MutationAddExternalUserArgs = {
  input: AddExternalUserInput;
};

export type MutationCreatePmxActivityArgs = {
  input: CreatePmxActivityInput;
};

export type MutationEditPmxActivityArgs = {
  input: EditPmxActivityInput;
};

export type MutationDeletePmxActivityArgs = {
  input: DeletePmxActivityInput;
};

export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};

export type MutationCreateArchiveArgs = {
  input: CreateArchiveInput;
};

export type MutationCreateConversationArgs = {
  input: CreateConversationInput;
};

export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};

export type MutationEditCommentArgs = {
  input: EditCommentInput;
};

export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};

export type MutationImportSwanContentArgs = {
  input: ImportSwanContentInput;
};

export type MutationCreateActionArgs = {
  input: CreateActionInput;
};

export type MutationUpdateActionStatusArgs = {
  input: UpdateActionStatusInput;
};

export type MutationEditAccessRightsArgs = {
  input?: InputMaybe<EditAccessRightsInput>;
};

export type MutationDeleteAccessRightsArgs = {
  input?: InputMaybe<DeleteAccessRightsInput>;
};

export type MutationExportToModspaceArgs = {
  input: ExportToModspaceInput;
};

export type MutationExportToSpmSvnArgs = {
  input: ExportToSpmSvnInput;
};

export type MutationImportModspaceContentArgs = {
  input: ImportModspaceContentInput;
};

export type MutationImportSpectrumDataArgs = {
  input: ImportSpectrumDataInput;
};

export type MutationEditActionArgs = {
  input: EditActionInput;
};

export type CreateUploadLinksOutput = {
  __typename?: "CreateUploadLinksOutput";
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
  presignedUrls?: Maybe<Array<UploadLink>>;
};

export type CreateMultipartUploadOutput = {
  __typename?: "CreateMultipartUploadOutput";
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
  uploadId?: Maybe<Scalars["String"]>;
};

export type MultipartUploadLink = {
  __typename?: "MultipartUploadLink";
  partNumber: Scalars["Int"];
  url: Scalars["String"];
};

export type GetMultipartUploadLinksOutput = {
  __typename?: "GetMultipartUploadLinksOutput";
  presignedUrls?: Maybe<Array<MultipartUploadLink>>;
};

export type CompleteMultipartUploadOutput = {
  __typename?: "CompleteMultipartUploadOutput";
  bucket?: Maybe<Scalars["String"]>;
  absolutePath?: Maybe<Scalars["String"]>;
  versionId?: Maybe<Scalars["String"]>;
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
};

export type UploadLink = {
  __typename?: "UploadLink";
  url: Scalars["String"];
  fields: Scalars["String"];
};

export type CreateArchiveOutput = {
  __typename?: "CreateArchiveOutput";
  actionId: Scalars["ID"];
  actionStatus: ActionStatus;
  archive?: Maybe<RepoObject>;
};

export type ImportSwanContentOutput = {
  __typename?: "ImportSwanContentOutput";
  actionId: Scalars["ID"];
  actionStatus: ActionStatus;
  imported?: Maybe<Array<RepoObject>>;
};

export type ImportModspaceContentOutput = {
  __typename?: "ImportModspaceContentOutput";
  actionId: Scalars["ID"];
  actionStatus: ActionStatus;
  imported?: Maybe<Array<RepoObject>>;
};

export type ExportToModspaceOutput = {
  __typename?: "ExportToModspaceOutput";
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
};

export type ExportToSpmSvnOutput = {
  __typename?: "ExportToSpmSvnOutput";
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
};

export type MapPathMd5Sum = {
  __typename?: "MapPathMd5Sum";
  path: Scalars["String"];
  md5Sum: Scalars["String"];
};

export type SpectrumStudy = {
  __typename?: "SpectrumStudy";
  id: Scalars["ID"];
  developmentPhase?: Maybe<Scalars["String"]>;
  studyKey?: Maybe<Scalars["Int"]>;
  studyTitle?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  therapeuticAreaName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type SpectrumDataset = {
  __typename?: "SpectrumDataset";
  label: Scalars["String"];
  type: Scalars["String"];
  id: Scalars["ID"];
  disabled: Scalars["Boolean"];
  blinded: Scalars["String"];
  dataType: Scalars["String"];
};

export type SpectrumTableQueryData = {
  __typename?: "SpectrumTableQueryData";
  encodedData: Scalars["String"];
};

export type ActivityTemplate = {
  __typename?: "ActivityTemplate";
  name: Scalars["String"];
  description: Scalars["String"];
  directories: Array<Scalars["String"]>;
};

export type UploadFileInput = {
  name: Scalars["String"];
  parentFolder: Scalars["String"];
  activityId: Scalars["ID"];
  fileContent: Scalars["String"];
};

export type CreateUploadLinksInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<InputMaybe<Scalars["String"]>>;
  md5sums: Array<InputMaybe<Scalars["String"]>>;
  actionFileTypes?: InputMaybe<Array<InputMaybe<ActionFileType>>>;
  actionId?: InputMaybe<Scalars["ID"]>;
  qualityCheckStatus?: InputMaybe<QualityCheckStatus>;
  uncompressOnUpload?: InputMaybe<Scalars["Boolean"]>;
};

export type RenameObjectsInput = {
  activityId: Scalars["ID"];
  absolutePath: Scalars["String"];
  newName: Scalars["String"];
  override?: InputMaybe<Scalars["Boolean"]>;
  comment?: InputMaybe<Scalars["String"]>;
};

export type CopyObjectsInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<InputMaybe<Scalars["String"]>>;
  destinationPath: Scalars["String"];
  override?: InputMaybe<Scalars["Boolean"]>;
  comment?: InputMaybe<Scalars["String"]>;
};

export type MoveObjectsInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<InputMaybe<Scalars["String"]>>;
  destinationPath: Scalars["String"];
  override?: InputMaybe<Scalars["Boolean"]>;
  comment?: InputMaybe<Scalars["String"]>;
};

export type HideObjectsInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<InputMaybe<Scalars["String"]>>;
  comment: Scalars["String"];
};

export type AddExternalUserInput = {
  email: Scalars["String"];
  username: Scalars["String"];
  name: Scalars["String"];
  company?: InputMaybe<Scalars["String"]>;
  telephone: Scalars["String"];
};

export type CreatePmxActivityInput = {
  trialNumber: Scalars["String"];
  bayNumber?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  trialShortDescription?: InputMaybe<Scalars["String"]>;
  isStandard?: InputMaybe<Scalars["Boolean"]>;
  readOnlyRepositories?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  source?: InputMaybe<ActivitySourceInput>;
};

export type ActivitySourceInput = {
  template?: InputMaybe<Scalars["String"]>;
  spmre?: InputMaybe<SpmReSourceInput>;
};

export type SpmReSourceInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  repoFolder?: InputMaybe<Scalars["String"]>;
  spmReFolder: Scalars["String"];
  spmRePaths?: Array<InputMaybe<Scalars["String"]>>;
  spmReRevision?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type ModspaceSourceInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  repoFolder?: InputMaybe<Scalars["String"]>;
  modspaceFolder: Scalars["String"];
  modspacePaths?: Array<InputMaybe<Scalars["String"]>>;
  modspaceRevision?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type EditPmxActivityInput = {
  id: Scalars["ID"];
  trialNumber: Scalars["String"];
  bayNumber?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  trialShortDescription?: InputMaybe<Scalars["String"]>;
  isStandard?: InputMaybe<Scalars["Boolean"]>;
  readOnlyRepositories?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type DeletePmxActivityInput = {
  id: Scalars["ID"];
};

export type CreateFolderInput = {
  name: Scalars["String"];
  parentFolder: Scalars["String"];
  activityId: Scalars["ID"];
};

export type CreateArchiveInput = {
  activityId: Scalars["ID"];
  parentFolderInputs: Scalars["String"];
  relativePathInputs: Array<InputMaybe<Scalars["String"]>>;
};

export type CreateConversationInput = {
  name: Scalars["String"];
  activityId: Scalars["ID"];
};

export type CreateCommentInput = {
  text: Scalars["String"];
  conversationId: Scalars["ID"];
  repliedToCommentId?: InputMaybe<Scalars["ID"]>;
  referencedFiles?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  referencedActions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type EditCommentInput = {
  id: Scalars["ID"];
  text: Scalars["String"];
  referencedFiles?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  referencedActions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type DeleteCommentInput = {
  id: Scalars["ID"];
};

export type ImportSwanContentInput = {
  activityId: Scalars["ID"];
  swanUser: Scalars["String"];
  encryptedPassword: Scalars["String"];
  parentFolder: Scalars["String"];
  absoluteSwanPaths: Array<InputMaybe<Scalars["String"]>>;
  qualityCheckStatus?: InputMaybe<QualityCheckStatus>;
  description?: InputMaybe<Scalars["String"]>;
};

export type ImportModspaceContentInput = {
  activityId: Scalars["ID"];
  modspaceUser: Scalars["String"];
  encryptedPassword: Scalars["String"];
  repoParentFolder: Scalars["String"];
  modspaceParentFolder: Scalars["String"];
  absoluteModspacePaths: Array<InputMaybe<Scalars["String"]>>;
  qualityCheckStatus?: InputMaybe<QualityCheckStatus>;
  description?: InputMaybe<Scalars["String"]>;
};

export type ImportSpectrumDataInput = {
  token: Scalars["String"];
  datasets: Array<Scalars["String"]>;
  format: Scalars["String"];
  studyKey: Scalars["Int"];
  studyId: Scalars["String"];
  showMaskedData: Scalars["Boolean"];
  pmxActivityId: Scalars["Int"];
  absolutePath: Scalars["String"];
  qualityCheckStatus?: InputMaybe<QualityCheckStatus>;
  description?: InputMaybe<Scalars["String"]>;
};

export type CreateActionInput = {
  actionType: ActionType;
  actionFiles: Array<ActionFileInputType>;
  activityId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  sourceFolder?: InputMaybe<Scalars["String"]>;
  actionStatus?: InputMaybe<ActionStatus>;
  actionSource?: InputMaybe<ActionSource>;
  actionStatusDescription?: InputMaybe<Scalars["String"]>;
  qualityCheckStatus?: InputMaybe<QualityCheckStatus>;
};

export type ActionFileInputType = {
  actionFileType: ActionFileType;
  versionId: Scalars["ID"];
};

export type UpdateActionStatusInput = {
  actionId: Scalars["ID"];
  actionStatus?: InputMaybe<ActionStatus>;
  actionStatusDescription?: InputMaybe<Scalars["String"]>;
};

export type EditAccessRightsInput = {
  repoId?: InputMaybe<Scalars["ID"]>;
  reader?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contributor?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type DeleteAccessRightsInput = {
  repoId?: InputMaybe<Scalars["ID"]>;
  reader?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contributor?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ExportToModspaceInput = {
  modspaceUsername: Scalars["String"];
  encryptedPassword: Scalars["String"];
  targetFolderPath: Scalars["String"];
  absolutePaths: Array<Scalars["String"]>;
  activityId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
};

export type ExportToSpmSvnInput = {
  spmSvnUsername: Scalars["String"];
  encryptedPassword: Scalars["String"];
  targetFolderPath: Scalars["String"];
  absolutePaths: Array<Scalars["String"]>;
  activityId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
};

export type CreateMultipartUploadInput = {
  absolutePath: Scalars["String"];
  md5sum: Scalars["String"];
  activityId: Scalars["ID"];
  actionFileType?: InputMaybe<ActionFileType>;
  qualityCheckStatus?: InputMaybe<QualityCheckStatus>;
  uncompressOnUpload?: InputMaybe<Scalars["Boolean"]>;
  actionId?: InputMaybe<Scalars["ID"]>;
};

export type GetMultipartUploadLinksInput = {
  activityId: Scalars["ID"];
  absolutePath: Scalars["String"];
  uploadId: Scalars["String"];
  partNumbers: Array<InputMaybe<Scalars["Int"]>>;
};

export type CompleteMultipartUploadInput = {
  activityId: Scalars["ID"];
  absolutePath: Scalars["String"];
  uploadId: Scalars["String"];
  partNumbers: Array<InputMaybe<Scalars["Int"]>>;
  eTags: Array<InputMaybe<Scalars["String"]>>;
};

export type GetSpectrumStudyListInput = {
  token: Scalars["String"];
};

export type GetDatasetForStudyInput = {
  token: Scalars["String"];
  studyKey: Scalars["Int"];
};

export type EditActionInput = {
  id: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  qualityCheckStatus?: InputMaybe<QualityCheckStatus>;
};

export type GetTableQueryDataInput = {
  token: Scalars["String"];
  studyName: Scalars["String"];
  tableName: Scalars["String"];
  showMaskedData: Scalars["Boolean"];
};
