import { gql, useLazyQuery } from "@apollo/client";
export const QUERY_GET_SVN_CONTENT = gql`
  query getSpmReContent(
    $username: String!
    $encryptedPassword: String!
    $spmReFolder: String!
    $spmReRevision: String
  ) {
    getSpmReContent(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        spmReFolder: $spmReFolder
        spmReRevision: $spmReRevision
      }
    ) {
      name
      size
    }
  }
`;

export function useGetSvnContentQuery() {
  const [getSpmReContent, { error, loading, data }] = useLazyQuery(
    QUERY_GET_SVN_CONTENT,
    { fetchPolicy: "no-cache" }
  );

  return {
    getSpmReContent,
    error,
    loading,
    data
  };
}
