import { gql, useMutation } from "@apollo/client";

export const MUTATION_EXPORT_TO_MODSPACE = gql`
  mutation exportToModspace(
    $username: String!
    $encryptedPassword: String!
    $modspaceFolder: String!
    $absolutePaths: [String!]!
    $description: String
    $targetFolder: String
    $activityId: ID!
  ) {
    exportToModspace(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        modspaceFolder: $modspaceFolder
        absolutePaths: $absolutePaths
        description: $description
        activityId: $activityId
        targetFolder: $targetFolder
      }
    ) {
      actionId
      actionStatus
    }
  }
`;

export const useExportToModspaceHook = () => {
  const [
    exportToModspace,
    {
      data: modspaceData,
      loading: loadingModspace,
      error: errorModspace,
      reset: resetModspaceMutation
    }
  ] = useMutation(MUTATION_EXPORT_TO_MODSPACE, {
    onError(err) {
      console.log(err);
    }
  });

  return {
    exportToModspace,
    modspaceData,
    loadingModspace,
    errorModspace,
    resetModspaceMutation
  };
};
