import { gql, useMutation } from "@apollo/client";

export const MUTATION_EXPORT_TO_SPM_SVN = gql`
  mutation exportToSpmRe(
    $username: String!
    $encryptedPassword: String!
    $spmReFolder: String!
    $targetFolder: String
    $absolutePaths: [String!]!
    $description: String
    $activityId: ID!
  ) {
    exportToSpmRe(
      input: {
        username: $username
        encryptedPassword: $encryptedPassword
        spmReFolder: $spmReFolder
        targetFolder: $targetFolder
        absolutePaths: $absolutePaths
        description: $description
        activityId: $activityId
      }
    ) {
      actionId
      actionStatus
    }
  }
`;

export const useExportToSpmSvnHook = () => {
  const [
    exportToSpmSvn,
    {
      data: spmSvnData,
      loading: loadingSpmSvn,
      error: errorSpmSvn,
      reset: resetSpmSvnMutation
    }
  ] = useMutation(MUTATION_EXPORT_TO_SPM_SVN, {
    onError(err) {
      console.log(err);
    }
  });

  return {
    exportToSpmSvn,
    spmSvnData,
    loadingSpmSvn,
    errorSpmSvn,
    resetSpmSvnMutation
  };
};
