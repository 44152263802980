import { Button, Form } from "react-bootstrap";
import { useEffect } from "react";

interface Props {
  userData: {
    name: string;
  };
  token: string;
}
// used by internal team to get easy access to the authentication token
export default function Token(props: Props) {
  function handleCopyButtonClick() {
    const textArea = document.getElementById(
      "token-textarea"
    ) as HTMLTextAreaElement | null;
    if (textArea) {
      textArea.focus();
      textArea.select();
      document.execCommand("Copy");
    }
  }
  useEffect(() => {
    document.title = "PHIL App - Token";
  }, []);

  return (
    <div className="app_content">
      <h2>
        <small>
          Dear {props.userData?.name}, you are signed in! <br />
        </small>
        <br />
        Token:
      </h2>
      <Form.Control
        id="token-textarea"
        as="textarea"
        rows={10}
        onClick={handleCopyButtonClick}
      >
        {props.token}
      </Form.Control>
      <br />
      <Button variant="primary" onClick={handleCopyButtonClick}>
        Copy
      </Button>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

Token.defaultProps = {
  userData: null,
  token: null
};
