import { gql, useQuery } from "@apollo/client";
export const LIST_IMAGES = gql`
  query listImages {
    listImages {
      imageId
      imageName
      platformDetails
      architecture
      createdAt
      software {
        name
        version
      }
    }
  }
`;

export function useListImagesQuery() {
  const {
    data: images,
    loading: isLoadingListImages,
    error: errorListImages
  } = useQuery(LIST_IMAGES, {
    context: { clientName: "interactive" }
  });

  return {
    images,
    isLoadingListImages,
    errorListImages
  };
}
