import BackupTable from "@mui/icons-material/BackupTable";
import { useEffect, useState, useContext } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Breadcrumb,
  NavDropdown,
  Table
} from "react-bootstrap";
import { useQueryGetSpectrumStudyList } from "../../backend/hooks/importExportMenu/spectrum/queryGetSpectrumStudyList";
import { useQueryGetDatasetForStudy } from "../../backend/hooks/importExportMenu/spectrum/queryGetDatasetForStudy";
import { useQueryTableQueryData } from "../../backend/hooks/importExportMenu/spectrum/queryGetTableQueryData";
import { useQueryTableDetailsData } from "../../backend/hooks/importExportMenu/spectrum/queryGetTableDetails";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import {
  encryptStringWithRsaPublicKey,
  getQualityCheckStatusKeyFromValue,
  QualityCheckStatus,
  QualityCheckStatusType,
  MAXIMUM_CLEAR_PASSWORD,
  generateRandom
} from "../../helpers/stringHelper";
import Error from "../abstractComponents/error";
import { useImportSpectrumDataMutation } from "../../backend/hooks/importExportMenu/spectrum/mutationImportDataFromSpectrum";
import { useImportSpectrumSchemaMutation } from "../../backend/hooks/importExportMenu/spectrum/mutationImportSchemaFromSpectrum";

import Success from "../abstractComponents/success";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { customFilters } from "../../helpers/tableViewHelper";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";

// Component that renders everything related to Spectrum Import
export default function ImportFromSpectrum(props: {
  currentFolder: string;
  disabled: boolean;
  refetchRepoObjects: Function;
  currentSubArray: [any];
  repository: any;
}) {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);

  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [selectedTables, setSelectedTables] = useState<any[]>([]);
  const [selectedStudyKey, setSelectedStudyKey] = useState(null);
  const [selectedStudySystem, setSelectedStudySystem] = useState(null);
  const [selectedStudyTherapeuticArea, setSelectedStudyTherapeuticArea] =
    useState(null);
  const [selectedStudyIndication, setSelectedStudyIndication] = useState(null);

  const [selectedSchema, setSelectedSchema] = useState<string | null>(null);
  const [pageSizeTable, setPageSizeTable] = useState(100);
  const [description, setDescription] = useState("");
  const [asOfSchemaImport, setAsOfSchemaImport] = useState("");
  const [selectedTable, setSelectedTable] = useState("");
  const [snapshotLabel, setSnapshotLabel] = useState("");
  const [schemaImportDataType, setSchemaImportDataType] = useState("NA/Dummy");
  const [selectedSchemaRows, setSelectedSchemaRows] = useState<any[]>([]);
  const [spectrumUsername, setSpectrumUsername] = useState(
    localStorage.getItem("spectrumUsername") || ""
  );
  const [spectrumPassword, setSpectrumPassword] = useState(
    localStorage.getItem("spectrumPassword") || ""
  );
  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatus | null>(null);

  const handleSelectMultipleRowsTables = (e: any) => {
    setSelectedTables(e);
  };
  const handleSelectMultipleSchemaRows = (e: any) => {
    setSelectedSchemaRows(e);
  };

  const {
    getSpectrumStudyList,
    studyList,
    isLoadingStudyList,
    errorStudyList
  } = useQueryGetSpectrumStudyList();

  const { getTableQueryData, tableData, isTableDataLoading, errorTableData } =
    useQueryTableQueryData();

  const {
    getSpectrumTableQueryData,
    tableDetailsData,
    isTableDetailsLoading,
    errorTableDetailsData
  } = useQueryTableDetailsData();

  const { getDatasetForStudy, dataset, isLoadingDataset, errorDataset } =
    useQueryGetDatasetForStudy();

  const {
    importSpectrumData,
    importSpectrumDataReturned,
    spectrumImportLoading,
    errorSpectrumImport
  } = useImportSpectrumDataMutation();

  const {
    importSpectrumSchema,
    importSpectrumSchemaReturned,
    spectrumImportSchemaLoading,
    errorSpectrumSchemaImport
  } = useImportSpectrumSchemaMutation();

  const importFromSpectrumCallerTable = () => {
    let weHaveSameNameSomewhere = false;

    // pares current array to see if anything matches
    props.currentSubArray.forEach((file: any) => {
      selectedTables.forEach((table: string) => {
        const studyNameProper = table.split("/").pop();
        if (
          file &&
          file.name &&
          studyNameProper &&
          file.name === `${table}.csv`
        ) {
          weHaveSameNameSomewhere = true;
        }
      });
    });

    if (
      weHaveSameNameSomewhere &&
      !window.confirm(
        "This import might overwrite files. Do you want to continue?"
      )
    ) {
      return;
    }

    importSpectrumData({
      variables: {
        username: spectrumUsername,
        encryptedPassword: spectrumPassword,
        tables: selectedTables,
        schema: selectedSchema,
        format: "CSV",
        asof: asOfSchemaImport,
        dataType: schemaImportDataType,
        pmxActivityId: activityId,
        absolutePath: props.currentFolder,
        qualityCheckStatus:
          getQualityCheckStatusKeyFromValue(qualityCheckStatus),
        description: description
      }
    }).then(() => {
      props.refetchRepoObjects();
      closeModal();
    });
  };

  const importFromSpectrumCallerSchema = () => {
    let weHaveSameNameSomewhere = false;
    // pares current array to see if anything matches
    props.currentSubArray.forEach((file: any) => {
      selectedSchemaRows.forEach((schema: string) => {
        if (file && file.name && file.name === schema) {
          weHaveSameNameSomewhere = true;
        }
      });
    });

    if (
      weHaveSameNameSomewhere &&
      !window.confirm(
        "This import might overwrite files. Do you want to continue?"
      )
    ) {
      return;
    }

    importSpectrumSchema({
      variables: {
        username: spectrumUsername,
        encryptedPassword: spectrumPassword,
        schemas: selectedSchemaRows,
        format: "CSV",
        asof: asOfSchemaImport,
        snapshotLabel: snapshotLabel ? snapshotLabel !== "" : null,
        dataType: schemaImportDataType,
        pmxActivityId: activityId,
        absolutePath: props.currentFolder,
        qualityCheckStatus:
          getQualityCheckStatusKeyFromValue(qualityCheckStatus),
        description: description
      }
    }).then(() => {
      props.refetchRepoObjects();
      closeModal();
    });
  };

  const tableColumns = [
    {
      field: "name",
      headerName: "name",
      flex: 2,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <Button
          variant="link"
          onClick={() => setSelectedTable(params.row.oracleName)}
        >
          {params.row.name}
        </Button>
      ),
      valueGetter: (params: any) => params.row.name
    },
    {
      field: "oracleName",
      headerName: "oracleName",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "sasName",
      headerName: "sasName",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "sasLabel",
      headerName: "sasLabel",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "type",
      headerName: "type",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "description",
      headerName: "description",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "audited",
      headerName: "audited",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "blinded",
      headerName: "blinded",
      flex: 2,
      filterOperators: customFilters
    }
  ];

  const datasetColumns = [
    {
      field: "name",
      headerName: "name",
      flex: 6,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <Button
          variant="link"
          onClick={() => setSelectedSchema(params.row.schema)}
        >
          {params.row.name}
        </Button>
      ),
      valueGetter: (params: any) => params.row.name
    },
    {
      field: "systemName",
      headerName: "systemName",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "lifeCycle",
      headerName: "lifeCycle",
      filterOperators: customFilters
    },
    {
      field: "businessArea",
      headerName: "businessArea",
      filterOperators: customFilters
    },
    {
      field: "schema",
      headerName: "schema",
      filterOperators: customFilters
    },
    {
      field: "description",
      headerName: "description",
      hide: true,
      filterOperators: customFilters
    },
    {
      field: "modelType",
      headerName: "modelType",
      hide: true,
      filterOperators: customFilters
    },
    {
      field: "modelSubType",
      headerName: "modelSubType",
      hide: true,
      filterOperators: customFilters
    },
    {
      field: "virtualModel",
      headerName: "virtualModel",
      filterOperators: customFilters
    },
    {
      field: "numberOfTables",
      headerName: "numberOfTables",
      filterOperators: customFilters
    },
    {
      field: "numberOfVirtualModels",
      headerName: "numberOfVirtualModels",
      filterOperators: customFilters
    },
    {
      field: "asof",
      headerName: "asof",
      hide: true,
      filterOperators: customFilters
    },
    {
      field: "dataFreeze",
      headerName: "dataFreeze",
      hide: true,
      filterOperators: customFilters
    },
    {
      field: "lastUpdate",
      headerName: "lastUpdate",
      filterOperators: customFilters
    }
  ];

  const studyColumns = [
    {
      field: "study",
      headerName: "study",
      flex: 2,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <Button
          variant="link"
          onClick={() => {
            setSelectedStudyKey(params.row.study);
            setSelectedStudySystem(params.row.system);
            setSelectedStudyTherapeuticArea(params.row.therapeuticArea);
            setSelectedStudyIndication(params.row.indication);
          }}
        >
          {params.row.study}
        </Button>
      ),
      valueGetter: (params: any) => params.row.study
    },
    {
      field: "system",
      headerName: "system",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "therapeuticArea",
      headerName: "Therapeutic Area",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "indication",
      headerName: "Indication",
      flex: 2,
      filterOperators: customFilters
    },
    {
      field: "drugProgramCode",
      headerName: "Drug Program Code",
      flex: 2,
      filterOperators: customFilters
    }
  ];

  //Function called for loging in
  const login = () => {
    if (
      spectrumPassword.length > MAXIMUM_CLEAR_PASSWORD &&
      spectrumPassword.endsWith("=")
    ) {
      getSpectrumStudyList({
        variables: {
          username: spectrumUsername,
          encryptedPassword: spectrumPassword
        }
      });
    } else {
      //there is no password encriptions
      const newPassword = encryptStringWithRsaPublicKey(spectrumPassword);
      setSpectrumPassword(newPassword);

      localStorage.setItem("spectrumUsername", spectrumUsername);
      localStorage.setItem("spectrumPassword", newPassword);

      getSpectrumStudyList({
        variables: {
          username: spectrumUsername,
          encryptedPassword: newPassword
        }
      });
    }
  };

  useEffect(() => {
    if (
      isImportModalVisible &&
      spectrumPassword.length > MAXIMUM_CLEAR_PASSWORD &&
      spectrumPassword.endsWith("=")
    ) {
      login();
    }
  }, [isImportModalVisible]);

  useEffect(() => {
    if (selectedStudyKey) {
      getDatasetForStudy({
        variables: {
          username: spectrumUsername,
          encryptedPassword: spectrumPassword,
          studyKey: selectedStudyKey,
          system: selectedStudySystem,
          therapeuticArea: selectedStudyTherapeuticArea,
          indication: selectedStudyIndication
        }
      });
    }
    setSelectedSchema(null);
    setSelectedTable("");
  }, [selectedStudyKey]);

  useEffect(() => {
    if (selectedSchema) {
      getTableQueryData({
        variables: {
          username: spectrumUsername,
          encryptedPassword: spectrumPassword,
          schema: selectedSchema
        }
      });
    }
    setSelectedTables([]);
    setSelectedTable("");
  }, [selectedSchema]);

  useEffect(() => {
    if (selectedTable) {
      getSpectrumTableQueryData({
        variables: {
          username: spectrumUsername,
          encryptedPassword: spectrumPassword,
          table: selectedTable,
          schema: selectedSchema
        }
      });
    }
  }, [selectedTable]);

  const closeModal = () => {
    setIsImportModalVisible(false);
    setSelectedStudyKey(null);
    setQualityCheckStatus(null);
    setDescription("");
    setAsOfSchemaImport("");
    setSelectedTables([]);
    setSelectedSchemaRows([]);
  };

  function changeQualityCheckedStatus(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    event.preventDefault();
    setQualityCheckStatus(
      QualityCheckStatus[event.target.value as QualityCheckStatusType]
    );
  }

  const openModal = () => {
    setIsImportModalVisible(true);
    setSelectedStudyKey(null);
    if (spectrumUsername && spectrumPassword) {
      login();
    }
  };

  return (
    <>
      <NavDropdown.Item
        size="small"
        onClick={openModal}
        disabled={props.disabled}
        id="importFromSpectrumItem"
      >
        <BackupTable /> Spectrum Import
      </NavDropdown.Item>
      <Modal
        show={isImportModalVisible}
        enforceFocus={false}
        onHide={closeModal}
        dialogClassName="modal-80w"
        aria-labelledby="importFromSpectrumTitle"
      >
        <Modal.Header closeButton>
          <Modal.Title id="importFromSpectrumTitle">
            Import from {selectedStudyKey && `${selectedStudyKey}`}
            {selectedSchema && `/${selectedSchema}`} into &quot;
            {activityData.trialNumber}/{props.currentFolder}&quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {selectedStudyKey ? (
              <>
                <Row>
                  <Col xs={12}>
                    <Breadcrumb>
                      <Breadcrumb.Item
                        onClick={() => {
                          setSelectedStudyKey(null);
                        }}
                      >
                        Studies
                      </Breadcrumb.Item>
                      {selectedStudyKey && (
                        <Breadcrumb.Item
                          onClick={() => {
                            setSelectedSchema(null);
                          }}
                        >
                          {selectedStudyKey}
                        </Breadcrumb.Item>
                      )}
                      {selectedSchema && (
                        <Breadcrumb.Item active>
                          {selectedSchema}
                        </Breadcrumb.Item>
                      )}
                    </Breadcrumb>
                  </Col>
                </Row>
                {(isLoadingDataset ||
                  spectrumImportLoading ||
                  spectrumImportSchemaLoading) && (
                  <div className="center_div">
                    <Spinner animation="border" className="spinner_color" />
                    <p>
                      Loading...{" "}
                      {spectrumImportLoading && "This might take a minute..."}
                    </p>
                  </div>
                )}
                {!spectrumImportLoading &&
                  !spectrumImportSchemaLoading &&
                  !isLoadingDataset &&
                  dataset &&
                  dataset.getDatasetForStudy && (
                    <>
                      {selectedSchema ? (
                        <>
                          {isTableDataLoading && (
                            <Row className="overflow-scroll table_details_spectrum">
                              <br />
                              <br />
                              <div className="center_div">
                                <Spinner
                                  animation="border"
                                  className="spinner_color"
                                />
                                <p>Loading...</p>
                              </div>
                            </Row>
                          )}
                          {tableData?.getTableQueryData && (
                            <>
                              <Row>
                                <Col xs={2}>QC Status:</Col>
                                <Col xs={2}>
                                  <Form.Select
                                    value={String(
                                      getQualityCheckStatusKeyFromValue(
                                        qualityCheckStatus
                                      )
                                    )}
                                    onChange={changeQualityCheckedStatus}
                                    key="swan_quality_controlled"
                                    id="importFromSwanQualityFormSelect"
                                  >
                                    <option key={null}>None</option>
                                    {Object.entries(QualityCheckStatus).map(
                                      (element) => (
                                        <option
                                          key={element[0]}
                                          value={element[0]}
                                        >
                                          {element[1]}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                </Col>
                                <Col xs={1}>
                                  <Form.Label>Description:</Form.Label>
                                </Col>

                                <Col xs={2}>
                                  <Form.Control
                                    type="text"
                                    required
                                    placeholder="Enter description"
                                    id="importFromSwanDescriptionFormControl"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col xs={2}>
                                  Blinding Type
                                  <Tooltip title="Leave as default – special privileges are required to access blinded data">
                                    <IconButton>
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  :
                                </Col>
                                <Col xs={2}>
                                  <Form.Select
                                    value={schemaImportDataType}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLSelectElement>
                                    ) => {
                                      event.preventDefault();
                                      setSchemaImportDataType(
                                        event.target.value
                                      );
                                    }}
                                    key="schema_import_data_type"
                                    id="schema_import_data_type"
                                  >
                                    <option value={"NA/Dummy"} key={"NA/Dummy"}>
                                      NA/Dummy
                                    </option>
                                    <option
                                      value={"Masked Data"}
                                      key={"Masked Data"}
                                    >
                                      Masked Data
                                    </option>
                                    <option
                                      value={"Real(BlindBreak)"}
                                      key={"Real(BlindBreak)"}
                                    >
                                      Real(BlindBreak)
                                    </option>
                                  </Form.Select>
                                </Col>
                                <Col xs={1}>
                                  <Form.Label>
                                    As Of
                                    <Tooltip title="can be used to retrieve data from a particular timepoint in the past, leave as default (blank) if you want the latest data or data from a snapshot">
                                      <IconButton>
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    :
                                  </Form.Label>
                                </Col>
                                <Col xs={2}>
                                  <Form.Control
                                    type="text"
                                    required
                                    placeholder="20190201T143620-0500"
                                    id="importSchemaAsOf"
                                    value={asOfSchemaImport}
                                    onChange={(e) =>
                                      setAsOfSchemaImport(e.target.value)
                                    }
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={2}>
                                  <Button
                                    variant="light"
                                    onClick={() => {
                                      setSelectedSchema(null);
                                    }}
                                  >
                                    <ArrowBackIcon className="w-auto align-self-center" />{" "}
                                    Back
                                  </Button>
                                </Col>
                                <Col xs={8}></Col>
                                <Col xs={2}>
                                  <Button
                                    className="float-end"
                                    disabled={
                                      selectedTables.length <= 0 ||
                                      spectrumImportLoading
                                    }
                                    onClick={importFromSpectrumCallerTable}
                                    id="importFromSpectrumButtonTable"
                                  >
                                    Import Tables
                                  </Button>
                                </Col>
                              </Row>
                              <br />
                              <Row className="overflow-scroll table_details_spectrum">
                                <DataGridPro
                                  rows={tableData?.getTableQueryData?.tables}
                                  columns={tableColumns}
                                  autoHeight
                                  checkboxSelection
                                  disableSelectionOnClick
                                  pagination
                                  selectionModel={selectedTables}
                                  rowsPerPageOptions={[10, 25, 50, 100]}
                                  onSelectionModelChange={
                                    handleSelectMultipleRowsTables
                                  }
                                  pageSize={pageSizeTable}
                                  onPageSizeChange={(pageSize) =>
                                    setPageSizeTable(pageSize)
                                  }
                                  getRowId={(row) => row.oracleName}
                                />
                              </Row>

                              {isTableDetailsLoading && (
                                <div className="center_div">
                                  <Spinner
                                    animation="border"
                                    className="spinner_color"
                                  />
                                  <p>Loading...</p>
                                </div>
                              )}

                              {selectedTable && tableDetailsData && (
                                <>
                                  <h2>{selectedTable}</h2>
                                  <Row>
                                    <Col xs={1}>
                                      <b>description</b>
                                    </Col>
                                    <Col xs={2}>
                                      {
                                        tableDetailsData
                                          ?.getSpectrumTableDetails?.description
                                      }
                                    </Col>

                                    <Col xs={1}>
                                      <b>oracleName</b>
                                    </Col>
                                    <Col xs={2}>
                                      {
                                        tableDetailsData
                                          ?.getSpectrumTableDetails?.oracleName
                                      }
                                    </Col>

                                    <Col xs={1}>
                                      <b>sasName</b>
                                    </Col>
                                    <Col xs={2}>
                                      {
                                        tableDetailsData
                                          ?.getSpectrumTableDetails?.sasName
                                      }
                                    </Col>

                                    <Col xs={1}>
                                      <b>sasLabel</b>
                                    </Col>
                                    <Col xs={2}>
                                      {
                                        tableDetailsData
                                          ?.getSpectrumTableDetails?.sasLabel
                                      }
                                    </Col>

                                    <Col xs={1}>
                                      <b>type</b>
                                    </Col>
                                    <Col xs={2}>
                                      {
                                        tableDetailsData
                                          ?.getSpectrumTableDetails?.type
                                      }
                                    </Col>

                                    <Col xs={1}>
                                      <b>audited</b>
                                    </Col>
                                    <Col xs={2}>
                                      {String(
                                        tableDetailsData
                                          ?.getSpectrumTableDetails?.audited
                                      )}
                                    </Col>

                                    <Col xs={1}>
                                      <b>blinded</b>
                                    </Col>
                                    <Col xs={2}>
                                      {String(
                                        tableDetailsData
                                          ?.getSpectrumTableDetails?.blinded
                                      )}
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Table striped bordered hover>
                                      <thead>
                                        <tr>
                                          <th>name</th>
                                          <th>sasName</th>
                                          <th>sasLabel</th>
                                          <th>sasFormat</th>
                                          <th>length</th>
                                          <th>dataType</th>
                                          <th>position</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {tableDetailsData?.getSpectrumTableDetails?.columns.map(
                                          (column: any) => (
                                            <tr
                                              key={`spectrum_column_${column.name}}`}
                                            >
                                              <td>{column.name}</td>
                                              <td>{column.sasName}</td>
                                              <td>{column.sasLabel}</td>
                                              <td>{column.sasFormat}</td>
                                              <td>{column.length}</td>
                                              <td>{column.dataType}</td>
                                              <td>{column.position}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </Row>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col xs={1}>Study Key:</Col>
                            <Col xs={1}>
                              <b>{selectedStudyKey}</b>
                            </Col>
                            <Col xs={1}>System:</Col>
                            <Col xs={1}>
                              <b>{selectedStudySystem}</b>
                            </Col>
                            {selectedStudyTherapeuticArea && (
                              <>
                                <Col xs={1}>Ther. Area:</Col>
                                <Col xs={1}>
                                  <b>{selectedStudyTherapeuticArea}</b>
                                </Col>
                              </>
                            )}
                            {selectedStudyIndication && (
                              <>
                                <Col xs={1}>Indication:</Col>
                                <Col xs={1}>
                                  <b>{selectedStudyIndication}</b>
                                </Col>
                              </>
                            )}
                          </Row>
                          <br />
                          <p className="font-weight-bold">
                            Import entire schema with checkboxes, or click for
                            table selection
                          </p>
                          <Row>
                            <Col xs={2}>QC Status:</Col>
                            <Col xs={2}>
                              <Form.Select
                                value={String(
                                  getQualityCheckStatusKeyFromValue(
                                    qualityCheckStatus
                                  )
                                )}
                                onChange={changeQualityCheckedStatus}
                                key="swan_quality_controlled"
                                id="importFromSwanQualityFormSelect"
                              >
                                <option key={null}>None</option>
                                {Object.entries(QualityCheckStatus).map(
                                  (element) => (
                                    <option key={element[0]} value={element[0]}>
                                      {element[1]}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </Col>
                            <Col xs={1}>
                              <Form.Label>Description:</Form.Label>
                            </Col>

                            <Col xs={2}>
                              <Form.Control
                                type="text"
                                required
                                placeholder="Enter description"
                                id="importFromSwanDescriptionFormControl"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col xs={2}>
                              Blinding Type
                              <Tooltip title="Leave as default – special privileges are required to access blinded data">
                                <IconButton>
                                  <InfoOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                              :
                            </Col>
                            <Col xs={2}>
                              <Form.Select
                                value={schemaImportDataType}
                                onChange={(
                                  event: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                  event.preventDefault();
                                  setSchemaImportDataType(event.target.value);
                                }}
                                key="schema_import_data_type"
                                id="schema_import_data_type"
                              >
                                <option value={"NA/Dummy"} key={"NA/Dummy"}>
                                  NA/Dummy
                                </option>
                                <option
                                  value={"Masked Data"}
                                  key={"Masked Data"}
                                >
                                  Masked Data
                                </option>
                                <option
                                  value={"Real(BlindBreak)"}
                                  key={"Real(BlindBreak)"}
                                >
                                  Real(BlindBreak)
                                </option>
                              </Form.Select>
                            </Col>
                            <Col xs={1}>
                              <Form.Label>
                                As Of
                                <Tooltip title="can be used to retrieve data from a particular timepoint in the past, leave as default (blank) if you want the latest data or data from a snapshot">
                                  <IconButton>
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                                :
                              </Form.Label>
                            </Col>
                            <Col xs={2}>
                              <Form.Control
                                type="text"
                                required
                                placeholder="20190201T143620-0500"
                                id="importSchemaAsOf"
                                value={asOfSchemaImport}
                                onChange={(e) =>
                                  setAsOfSchemaImport(e.target.value)
                                }
                              />
                            </Col>
                            {selectedSchemaRows.length < 2 && (
                              <>
                                <Col xs={2}>
                                  <Form.Label>
                                    Snapshot Label
                                    <Tooltip title="leave blank, since snapshot label is part of the BA name">
                                      <IconButton>
                                        <InfoOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    :
                                  </Form.Label>
                                </Col>
                                <Col xs={2}>
                                  <Form.Control
                                    type="text"
                                    required
                                    placeholder="Enter Snapshot Label"
                                    id="importSnapshotLabel"
                                    value={snapshotLabel}
                                    onChange={(e) =>
                                      setSnapshotLabel(e.target.value)
                                    }
                                  />
                                </Col>
                              </>
                            )}
                            <Col xs={1}>
                              <Button
                                className="float-end"
                                disabled={
                                  selectedSchemaRows.length <= 0 ||
                                  spectrumImportLoading
                                }
                                onClick={importFromSpectrumCallerSchema}
                                id="importFromSpectrumButton"
                              >
                                Import
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <DataGridPro
                              rows={dataset.getDatasetForStudy}
                              columns={datasetColumns}
                              autoHeight
                              disableSelectionOnClick
                              pagination
                              selectionModel={selectedSchemaRows}
                              checkboxSelection
                              onSelectionModelChange={
                                handleSelectMultipleSchemaRows
                              }
                              rowsPerPageOptions={[10, 25, 50, 100]}
                              pageSize={pageSizeTable}
                              onPageSizeChange={(pageSize) =>
                                setPageSizeTable(pageSize)
                              }
                              getRowId={(row) => row.schema.replace("\n", "")}
                            />
                          </Row>

                          <br />
                          <Row>
                            <Col xs={2}>
                              <Button
                                variant="light"
                                onClick={() => {
                                  setSelectedStudyKey(null);
                                }}
                              >
                                <ArrowBackIcon className="w-auto align-self-center" />{" "}
                                Back
                              </Button>
                            </Col>
                            <Col xs={10}></Col>
                          </Row>
                        </>
                      )}
                    </>
                  )}
              </>
            ) : (
              <>
                <Row
                  className={
                    spectrumPassword.length > MAXIMUM_CLEAR_PASSWORD
                      ? "login_grey_color_row"
                      : ""
                  }
                >
                  <Col xs={3}>
                    <Form.Label>Spectrum Username:</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="text"
                      placeholder="Enter username"
                      id="importFromSpectrumUsernameFormControl"
                      value={spectrumUsername}
                      onChange={(e) => setSpectrumUsername(e.target.value)}
                    />
                  </Col>
                  <Col></Col>
                </Row>
                <Row
                  className={
                    spectrumPassword.length > MAXIMUM_CLEAR_PASSWORD
                      ? "login_grey_color_row"
                      : ""
                  }
                >
                  <Col xs={3}>
                    <Form.Label>
                      Spectrum Password{" "}
                      <Tooltip title="Use the initial(!) Spectrum password">
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      :
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="password"
                      placeholder="Enter password"
                      id="importFromSpectrumPasswordFormControl"
                      value={spectrumPassword}
                      onChange={(e) => setSpectrumPassword(e.target.value)}
                    />
                  </Col>
                  <Col xs={2}>
                    <Button
                      variant={"light"}
                      onClick={login}
                      id="loginButtonSpectrum"
                    >
                      Get List
                    </Button>
                  </Col>
                </Row>
                <br />
                <>
                  {isLoadingStudyList && (
                    <div className="center_div">
                      <Spinner animation="border" className="spinner_color" />
                      <p>Loading...</p>
                    </div>
                  )}
                  {!isLoadingStudyList &&
                    studyList &&
                    studyList.getSpectrumStudyList && (
                      <>
                        <br />
                        <Row>
                          <DataGridPro
                            rows={studyList.getSpectrumStudyList}
                            getRowId={() => generateRandom()}
                            columns={studyColumns}
                            rowHeight={60}
                            autoHeight
                            disableSelectionOnClick
                            pagination
                            pageSize={100}
                          />
                        </Row>
                      </>
                    )}
                </>
              </>
            )}
          </Container>
        </Modal.Body>
      </Modal>
      {(errorStudyList ||
        errorDataset ||
        errorSpectrumImport ||
        errorSpectrumSchemaImport ||
        errorTableDetailsData ||
        errorTableData) && (
        <Error
          error={
            errorStudyList ||
            errorDataset ||
            errorSpectrumImport ||
            errorSpectrumSchemaImport ||
            errorTableDetailsData ||
            errorTableData
          }
        />
      )}
      {(importSpectrumDataReturned || importSpectrumSchemaReturned) &&
        !errorSpectrumImport &&
        !errorSpectrumSchemaImport && (
          <Success message="Spectrum import started. It can take up to 15 Minutes." />
        )}
    </>
  );
}
