import {
  GridColDef,
  GridSortDirection,
  GridSortModel,
  GridValueGetterParams,
  DataGridPro
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetAuditTrailEventsQuery } from "../backend/hooks/queryGetAuditTrailEvents";
import Error from "./abstractComponents/error";
import { renderCellExpand } from "./abstractComponents/renderCellExpand";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { customFilters } from "../helpers/tableViewHelper";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { useDownloadAuditEvents } from "../backend/hooks/auditTrail/mutationDownloadAuditEvents";
import Success from "./abstractComponents/success";

export const columns: GridColDef[] = [
  {
    field: "userId",
    headerName: "User",
    width: 200,
    filterOperators: customFilters
  },
  {
    field: "created",
    headerName: "Created",
    flex: 1,
    filterOperators: customFilters
  },
  {
    field: "repository",
    headerName: "Repository",
    flex: 1,
    filterOperators: customFilters,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.repository &&
      (params.row.repository.name
        ? params.row.repository.name
        : params.row.repository.id + " (Deleted)")
  },
  {
    field: "actionType",
    headerName: "Action Type",
    width: 150,
    filterOperators: customFilters
  },
  {
    field: "data",
    headerName: "Data",
    flex: 8,
    filterOperators: customFilters,
    valueGetter: (params: GridValueGetterParams) => {
      let rowData = params.row.data.replace("Archive", "Compress");
      let jsonRowData = JSON.parse(rowData);
      if (
        jsonRowData.old_values &&
        jsonRowData.old_values.quality_check_status === null
      ) {
        jsonRowData.old_values.quality_check_status = "None";
      }

      if (
        jsonRowData.new_values &&
        jsonRowData.new_values.quality_check_status === null
      ) {
        jsonRowData.new_values.quality_check_status = "None";
      }

      return JSON.stringify(jsonRowData);
    },
    renderCell: renderCellExpand
  }
];

export function AuditTrail() {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "created",
      sort: "desc" as GridSortDirection
    }
  ]);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);

  const { auditTrailEvent, isLoading, error } = useGetAuditTrailEventsQuery(
    page,
    pageSize
  );

  const [rowCountState, setRowCountState] = useState(
    auditTrailEvent?.getAuditTrailEvents?.totalRowCount || 0
  );

  const {
    downloadAuditEventsMutation,
    downloadAuditEventsMutationData,
    isLoadingDownloadMutation,
    errorDownloadAuditEventsMutation
  } = useDownloadAuditEvents();

  const handleDownloadAuditTrail = () => {
    downloadAuditEventsMutation();
  };

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      auditTrailEvent?.getAuditTrailEvents?.totalRowCount !== undefined
        ? auditTrailEvent?.getAuditTrailEvents?.totalRowCount
        : prevRowCountState
    );
  }, [auditTrailEvent?.getAuditTrailEvents?.totalRowCount]);

  useEffect(() => {
    document.title = "PHIL App - Audit Trail";
  }, []);

  return (
    <div className="app_content">
      <h2>Audit Trail Events</h2>
      <Row>
        <Col></Col>
        <Col>
          <Button
            id="download_audit_trail"
            className="float-end button-secondary"
            onClick={handleDownloadAuditTrail}
          >
            <DownloadingIcon /> Full Export to email
          </Button>
        </Col>
      </Row>
      <br />
      {(isLoadingDownloadMutation || isLoading) && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      )}
      {!isLoading && !error && (
        <Container fluid>
          <div className="list_repositories_container">
            {auditTrailEvent?.getAuditTrailEvents &&
            auditTrailEvent.getAuditTrailEvents.auditTrail.length > 0 ? (
              <DataGridPro
                rows={auditTrailEvent.getAuditTrailEvents.auditTrail}
                rowCount={rowCountState}
                columns={columns}
                className="styled_data_grid"
                pageSize={pageSize}
                onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                pagination
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                getRowHeight={() => "auto"}
                disableSelectionOnClick
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                autoHeight
                paginationMode={"server"}
                rowsPerPageOptions={[25, 50, 100, 1000]}
              />
            ) : (
              <div className="no_repositories_available_div">
                No Actions are accessible
              </div>
            )}
            {downloadAuditEventsMutationData?.downloadAuditTrailEvents
              ?.actionStatus === "Pending" && (
              <Success message="Audit trails export has started. It can take up to 15 Minutes to email the link once completed." />
            )}
          </div>
        </Container>
      )}
      {error && <Error error={error} />}
      {errorDownloadAuditEventsMutation && (
        <Error error={errorDownloadAuditEventsMutation} />
      )}
    </div>
  );
}
