import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { GET_DOWNLOAD_LINKS } from "../../backend/hooks/importExportMenu/queryGetDownloadLinks";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import Error from "../abstractComponents/error";
import { Spinner } from "react-bootstrap";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

interface PdfPreviewProps {
  absolutePath: string;
  versionId: string;
  repoId: string;
}

export function PdfPreview(props: PdfPreviewProps) {
  const { activityData } = useContext(PmxActivityDetailsContext);

  const {
    error: errorDownload,
    data: downloadUrls,
    loading
  } = useQuery(GET_DOWNLOAD_LINKS, {
    variables: {
      absolutePaths: [props.absolutePath],
      activityId: Number(activityData.id),
      versionIds: props.versionId ? [props.versionId] : [],
      repoId: props.repoId
    }
  });
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return (
    <>
      {loading && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      )}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
        <div className="pdf_preview">
          {downloadUrls?.getDownloadLinks?.presignedUrls[0].url && (
            // style here is as provided by documentation
            <div
              className="rpv-core__viewer"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%"
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "#eeeeee",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  padding: "4px"
                }}
              >
                <Toolbar />
              </div>
              <div
                style={{
                  flex: 1,
                  overflow: "hidden"
                }}
              >
                <Viewer
                  fileUrl={downloadUrls?.getDownloadLinks?.presignedUrls[0].url}
                  plugins={[toolbarPluginInstance]}
                />
              </div>
            </div>
          )}
        </div>
      </Worker>

      {errorDownload && <Error error={errorDownload} />}
    </>
  );
}
