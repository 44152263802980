import { useEffect, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import {
  encryptStringWithRsaPublicKey,
  MAXIMUM_CLEAR_PASSWORD
} from "../../helpers/stringHelper";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useGetSvnMetadataQuery } from "../../backend/hooks/importExportMenu/queryGetSvnMetadata";
import { useGetModspaceMetadataQuery } from "../../backend/hooks/importExportMenu/queryGetModspaceMetadata";
import Error from "../abstractComponents/error";
import { customFilters } from "../../helpers/tableViewHelper";
import FolderIcon from "@mui/icons-material/Folder";
import {
  DataGridPro,
  GridRowId,
  GridSelectionModel
} from "@mui/x-data-grid-pro";
import {
  InputMaybe,
  ModspaceSourceInput,
  SpmReMeta,
  ModspaceMeta,
  SpmReSourceInput,
  PmxActivity
} from "../../backend/types";
import { Button, Form } from "react-bootstrap";

export enum SVNType {
  SPMRE = "SPMRE",
  Modspace = "Modspace",
  Template = "Template",
  Empty = "Empty"
}
interface SvnImportProps {
  setSvnDetails: React.Dispatch<React.SetStateAction<any>>;
  svnDetails: any;
  svnType: SVNType;
  modspaceFolder?: InputMaybe<string>;
  activities?: PmxActivity[];
}

interface SvnAttributes {
  name: string;
  revisions: string[];
}

export default function SvnImport(props: SvnImportProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginUsername, setLoginUsername] = useState<string>("");
  const [loginPassword, setLoginPassword] = useState<string>("");
  const [selectedSvn, setSelectedSvn] = useState<
    SpmReMeta | ModspaceMeta | null
  >(null);
  const [selectedSvnAttributes, setSelectedSvnAttributes] =
    useState<SvnAttributes | null>(null);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [search, setSearch] = useState<string>("");

  const { getSpmReMeta, errorMetadata, loadingMetadata, metadata } =
    useGetSvnMetadataQuery();

  const {
    getModspaceMeta,
    errorModspaceMetadata,
    loadingModspaceMetadata,
    modspaceMetadata
  } = useGetModspaceMetadataQuery();

  const updateSvnDetails = (
    values: {
      key: keyof SpmReSourceInput | keyof ModspaceSourceInput;
      value: string;
    }[]
  ) => {
    return props.setSvnDetails((prevState: any) => {
      const newState = values.reduce(
        (state, newValue) => {
          state[newValue.key] = newValue.value as string & InputMaybe<string>[];
          return state;
        },
        { ...prevState }
      );
      return newState;
    });
  };

  // todo: fix columns for modspace
  const columnsMetadata = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <div key={params.row.name} style={{ width: "100%" }}>
          <FolderIcon
            className="grey_color folder_icon"
            style={{ fontSize: 50 }}
          />
          <span className="folder_name_table_view_span">{params.row.name}</span>
          {props.activities?.find((activity: PmxActivity) => {
            const source = JSON.parse(activity?.source);
            return (
              source?.modspaceFolder === params.row.name ||
              source?.spmReFolder === params.row.name
            );
          }) && <span className="red_form"> (Already Imported)</span>}
        </div>
      ),
      valueGetter: (params: any) => params.row.name
    },
    {
      field: "revisions",
      headerName: "Last Revision",
      flex: 2,
      filterOperators: customFilters,
      valueGetter: (params: any) => params.row.revisions.slice(-1)[0],
      renderCell: (params: any) => params.row.revisions.slice(-1)[0]
    }
  ];

  // todo: fix footer for modspace
  const CustomFooter = () => {
    if (props.svnType === SVNType.SPMRE) {
      return (
        <div className="my-4 d-flex justify-content-between">
          <div>{selectionModel[0]?.toString()}</div>
          {metadata?.getSpmReMeta && (
            <div>Total Rows: {metadata?.getSpmReMeta?.length}</div>
          )}
        </div>
      );
    } else if (props.svnType === SVNType.Modspace) {
      return (
        <div className="my-4 d-flex justify-content-between">
          <div>{selectionModel[0]?.toString()}</div>
          {modspaceMetadata?.getModspaceMeta && (
            <div>Total Rows: {modspaceMetadata?.getModspaceMeta?.length}</div>
          )}
        </div>
      );
    }
  };

  const encryptAndSetPassword = () => {
    const encryptedPassword = encryptStringWithRsaPublicKey(loginPassword);
    setLoginPassword(encryptedPassword);
    if (props.svnType === SVNType.SPMRE) {
      localStorage.setItem("svnUsername", loginUsername);
      localStorage.setItem("svnPassword", encryptedPassword);
    } else if (props.svnType === SVNType.Modspace) {
      localStorage.setItem("modspaceUsername", loginUsername);
      localStorage.setItem("modspacePassword", encryptedPassword);
    }

    updateSvnDetails([
      { key: "username", value: loginUsername },
      { key: "encryptedPassword", value: encryptedPassword }
    ]);

    return encryptedPassword;
  };

  const handleOnRowClick = (grid: GridSelectionModel) => {
    if (
      (props.svnType === SVNType.SPMRE &&
        grid[0] === (selectedSvn as SpmReMeta)?.spmReFolder) ||
      (props.svnType === SVNType.Modspace &&
        grid[0] === (selectedSvn as ModspaceMeta)?.modspaceFolder)
    ) {
      setSelectionModel([]);
      setSelectedSvn(null);
      setSelectedSvnAttributes(null);

      updateSvnDetails([
        { key: "spmReFolder", value: "" },
        { key: "spmReRevision", value: "" },
        { key: "modspaceFolder", value: "" },
        { key: "modspaceRevision", value: "" }
      ]);
    } else {
      setSelectionModel(grid);
      if (props.svnType === SVNType.SPMRE) {
        updateSvnDetails([{ key: "spmReFolder", value: grid[0] as string }]);
        const select = metadata?.getSpmReMeta.find((svn: SpmReMeta) => {
          return svn.spmReFolder === grid[0];
        });
        setSelectedSvnAttributes({
          name: select.spmReFolder,
          revisions: select.spmReRevisions
        });
      } else if (props.svnType === SVNType.Modspace) {
        updateSvnDetails([{ key: "modspaceFolder", value: grid[0] as string }]);
        const select = modspaceMetadata?.getModspaceMeta.find(
          (svn: ModspaceMeta) => {
            return svn.modspaceFolder === grid[0];
          }
        );
        setSelectedSvnAttributes({
          name: select.modspaceFolder,
          revisions: select.modspaceRevisions
        });
      }
    }
  };

  const showRevision = () => {
    if (props.svnType === SVNType.SPMRE) {
      return props.svnDetails?.spmReRevision
        ? props.svnDetails?.spmReRevision
        : "HEAD";
    } else {
      return props.svnDetails?.modspaceRevision
        ? props.svnDetails?.modspaceRevision
        : "HEAD";
    }
  };

  function resetFields(): void {
    setSelectionModel([]);
    setSelectedSvn(null);
    setSelectedSvnAttributes(null);
    props.setSvnDetails(null);
    setSearch("");
  }

  const searchSVN = (
    encryptedPassword: string
  ): {
    key: keyof SpmReSourceInput | keyof ModspaceSourceInput;
    value: string;
  } => {
    if (props.svnType === SVNType.SPMRE) {
      getSpmReMeta({
        variables: {
          username: loginUsername,
          encryptedPassword: encryptedPassword,
          search: search
        }
      });

      return { key: "spmReRevision", value: "HEAD" };
    } else {
      getModspaceMeta({
        variables: {
          username: loginUsername,
          encryptedPassword: encryptedPassword,
          search: search
        }
      });

      return { key: "modspaceRevision", value: "HEAD" };
    }
  };

  useEffect(() => {
    if (search !== "" && loginUsername !== "" && loginPassword !== "") {
      if (
        search.length > 2 &&
        loginUsername.length > 0 &&
        loginPassword.length > 0
      ) {
        // wait 1 sec before the call
        const timeOutId = setTimeout(() => {
          let encryptedPassword = loginPassword;
          if (
            loginPassword?.length < MAXIMUM_CLEAR_PASSWORD &&
            !loginPassword.endsWith("=")
          ) {
            encryptedPassword = encryptAndSetPassword();
          }

          const revisionType: {
            key: keyof SpmReSourceInput | keyof ModspaceSourceInput;
            value: string;
          } = searchSVN(encryptedPassword);
          const updateValues: {
            key: keyof SpmReSourceInput | keyof ModspaceSourceInput;
            value: string;
          }[] = [
            revisionType,
            { key: "username", value: loginUsername },
            { key: "encryptedPassword", value: encryptedPassword }
          ];

          updateSvnDetails(updateValues);
        }, 1000);

        return () => clearTimeout(timeOutId);
      }
    }
  }, [search, loginUsername, loginPassword]);

  useEffect(() => {
    resetFields();
    if (props.svnType === SVNType.SPMRE) {
      setLoginUsername(
        localStorage.getItem("svnUsername")
          ? (localStorage.getItem("svnUsername") as string)
          : ""
      );
      setLoginPassword(
        localStorage.getItem("svnPassword")
          ? (localStorage.getItem("svnPassword") as string)
          : ""
      );
    } else {
      setLoginUsername(
        localStorage.getItem("modspaceUsername")
          ? (localStorage.getItem("modspaceUsername") as string)
          : ""
      );
      setLoginPassword(
        localStorage.getItem("modspacePassword")
          ? (localStorage.getItem("modspacePassword") as string)
          : ""
      );
    }
  }, [props.svnType]);

  return (
    <>
      <div
        className={
          loginPassword?.length > MAXIMUM_CLEAR_PASSWORD
            ? "login_grey_color_row"
            : ""
        }
      >
        <TextField
          className="w-100 mb-3"
          label={`${
            props.svnType === SVNType.SPMRE ? SVNType.SPMRE : SVNType.Modspace
          } Username`}
          required
          placeholder="Enter username"
          data-testid="importFromSvnUserFormControl"
          id="importFromSvnUserFormControl"
          value={loginUsername}
          onChange={(e) => setLoginUsername(e.target.value)}
        />
        <TextField
          className="w-100 mb-3"
          label={`${
            props.svnType === SVNType.SPMRE ? SVNType.SPMRE : SVNType.Modspace
          } Password`}
          required
          type={
            showPassword && loginPassword?.length < MAXIMUM_CLEAR_PASSWORD
              ? "text"
              : "password"
          }
          placeholder="Enter password"
          id="importFromSvnPasswordFormControl"
          value={loginPassword}
          onChange={(e) => setLoginPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={(e) => e.preventDefault()}
              >
                {loginPassword?.length > MAXIMUM_CLEAR_PASSWORD ? (
                  <></> ? (
                    showPassword
                  ) : (
                    <VisibilityOff />
                  )
                ) : (
                  <Visibility />
                )}
              </IconButton>
            )
          }}
        />
      </div>
      <TextField
        className="w-100 mb-3"
        id="importFromSvnLocationFormControl"
        label="Location"
        placeholder=""
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        helperText="Enter at least 3 characters"
      />
      {/* todo: fix for modspace */}
      <DataGridPro
        rows={
          metadata?.getSpmReMeta && props.svnType !== SVNType.Modspace
            ? metadata?.getSpmReMeta.map((svnEntry: any) => ({
                name: svnEntry.spmReFolder,
                revisions: svnEntry.spmReRevisions
              }))
            : (props.svnType === SVNType.Modspace &&
                modspaceMetadata?.getModspaceMeta.map((modspaceEntry: any) => ({
                  name: modspaceEntry.modspaceFolder,
                  revisions: modspaceEntry.modspaceRevisions
                }))) ||
              []
        }
        columns={columnsMetadata}
        loading={loadingMetadata || loadingModspaceMetadata}
        pageSize={10}
        rowHeight={60}
        autoHeight
        className="svn-metadata-table"
        getRowId={(row) => row.name}
        disableMultipleSelection={true}
        selectionModel={selectionModel}
        onSelectionModelChange={(selection) => handleOnRowClick(selection)}
        components={{
          Footer: CustomFooter
        }}
      />
      {selectedSvnAttributes && (
        <>
          <Form.Label>Revisions</Form.Label>
          <Form.Select
            aria-label="HEAD"
            value={showRevision()}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              props.svnType === SVNType.SPMRE
                ? updateSvnDetails([
                    { key: "spmReRevision", value: event.target.value }
                  ])
                : updateSvnDetails([
                    { key: "modspaceRevision", value: event.target.value }
                  ]);
            }}
            key="edit-revision"
            id="revision-number-select"
          >
            <option>HEAD</option>
            {selectedSvnAttributes?.revisions
              .slice(0)
              .reverse()
              .map((revisionNumber: string) => (
                <option key={revisionNumber} value={revisionNumber}>
                  {revisionNumber}
                </option>
              ))}
          </Form.Select>
          <Button
            className="mt-3 d-flex ms-auto"
            data-testid="reset-import-button"
            variant="primary"
            onClick={resetFields}
          >
            Reset
          </Button>
        </>
      )}

      {(errorMetadata || errorModspaceMetadata) && (
        <Error error={errorMetadata || errorModspaceMetadata} />
      )}
    </>
  );
}
